import React, { useState, useEffect } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Winner.css'
import TC from '../Components/Assets/Images/TC3.png'
import pro from '../Components/Assets/Images/defaultprofilepic.png'
import cal from '../Components/Assets/Images/calender.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Axios from 'axios';
import bg from '../Components/Assets/Images/M4.png'
import { BASE_URL } from '../Components/BaseURL'
import cong from '../Components/Assets/Images/congrats.gif'
 
function MobileWinnerApp() {
var mybutton = document.getElementById("myBtn");
window.onscroll = function() {scrollFunction()};
function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

  window.postMessage("Sending data from WebView");
  const [load,setLoad]=useState("");
  const [congrats,setcongrats]=useState("");
  const[Today1, setToday1] = useState([])
  const[Today2, setToday2] = useState([])
  const[Today3, setToday3] = useState([])
  const[Yesterday1, setYesterday1] = useState([])
  const[Yesterday2, setYesterday2] = useState([])
  const[Yesterday3, setYesterday3] = useState([])
  const [Week1, setWeek1] = useState([]);
  const [Week2, setWeek2] = useState([]);
  const [Week3, setWeek3] = useState([]);
  const [Week4, setWeek4] = useState([]);
  const [Week5, setWeek5] = useState([]);
  const [Week6, setWeek6] = useState([]);
  const [Week7, setWeek7] = useState([]);
  const [Week8, setWeek8] = useState([]);
  const [Week9, setWeek9] = useState([]);
  const [Week10, setWeek10] = useState([]);
  const [Week11, setWeek11] = useState([]);
  const [Week12, setWeek12] = useState([]);
  const [Week13, setWeek13] = useState([]);
  const [Week14, setWeek14] = useState([]);
  const [Week15, setWeek15] = useState([]);
useEffect(() => {
  setTimeout(() => {setLoad(true);}, 2000);
  setTimeout(() => {setcongrats(true);}, 3500);
  Axios({
      method: "GET",
      url:`${BASE_URL}Winner/winnertodayapp`
    }).then((resp) => {
      if(resp.data[0]!=null){
        setToday1(resp.data[0])
      }
      if(resp.data[1]!=null){
        setToday2(resp.data[1])
      }
      if(resp.data[2]!=null){
        setToday3(resp.data[2])
      }
     
     
    })
    Axios({
      method: "GET",
      url: `${BASE_URL}Winner/winneryesterday`
    }).then((resp) =>{
      if(resp.data[0]!=null){
        setYesterday1(resp.data[0])
      }
      if(resp.data[1]!=null){
        setYesterday2(resp.data[1])
      }
      if(resp.data[2]!=null){
        setYesterday3(resp.data[2])
      }
     
     
   
    })

    Axios({
      method: "GET",
      url: `${BASE_URL}Winner/winnerweekly`
    }).then((resp)=>{
      if(resp.data[0]!=null){
        setWeek1(resp.data[0])
      }
      if(resp.data[1]!=null){
        setWeek2(resp.data[1])
      }
      if(resp.data[2]!=null){
        setWeek3(resp.data[2])
      }
      if(resp.data[3]!=null){
        setWeek4(resp.data[3])
      }
      if(resp.data[4]!=null){
        setWeek5(resp.data[4])
      }
      if(resp.data[5]!=null){
        setWeek6(resp.data[5])
      }
      if(resp.data[6]!=null){
        setWeek7(resp.data[6])
      }
      if(resp.data[7]!=null){
        setWeek8(resp.data[7])
      }
      if(resp.data[8]!=null){
        setWeek9(resp.data[8]) 
      }
      if(resp.data[9]!=null){
        setWeek10(resp.data[9])
      }
      if(resp.data[10]!=null){
        setWeek11(resp.data[10])
      }
      if(resp.data[11]!=null){
        setWeek12(resp.data[11])
      }
      if(resp.data[12]!=null){
        setWeek13(resp.data[12])
      }
      if(resp.data[13]!=null){
        setWeek14(resp.data[13])
      }
      if(resp.data[14]!=null){
        setWeek15(resp.data[14])
      }
    
    })
}, [])      
    return(
      <body>
<div className="bg-image bgp-win">
<h1 className="text-winner-win text-top-win" id='content1'>Winners</h1>
{!congrats ? 
<img className="cong-img" src={cong}></img>
 : null}
<div>
{/* <button onClick={() => topFunction()} id="myBtn" title="Go to top"><i className="fa fa-arrow-up top-arrow" aria-hidden="true"></i></button> */}
<h1 className="text-winner-win text-top-win" id='content1'>Today's Winners</h1>
<Carousel autoPlay={true}
interval={3500}
showArrows={false}
showThumbs={false}
transitionTime={350}
emulateTouch={true}
swipeable={true}
infiniteLoop={true}>
 {Today1.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">

  <img src={Today1.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Today1.profile_name}</p>
  {Today1.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Today1.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>} 

{Today2.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <img src={Today2.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Today2.profile_name}</p>
  {Today2.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Today2.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>} 

{Today3.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <img src={Today3.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Today3.profile_name}</p>
  {Today3.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Today3.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>} 
</Carousel>

<h1 className="text-winner-win text-top-win" id='content1'>Yesterday's Winners</h1>
<Carousel autoPlay={true}
interval={2500}
showArrows={false}
showThumbs={false}
transitionTime={350}
emulateTouch={true}
swipeable={true}
infiniteLoop={true}>
 

 {Yesterday1.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <img src={Yesterday1.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Yesterday1.profile_name}</p>
  {Yesterday1.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Yesterday1.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>} 


{Yesterday2.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <img src={Yesterday2.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Yesterday2.profile_name}</p>
  {Yesterday2.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Yesterday2.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>} 


{Yesterday3.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <img src={Yesterday3.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Yesterday3.profile_name}</p>
  {Yesterday3.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Yesterday3.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>} 
</Carousel>


<h1 className="text-winner-win text-top-win" id='content1'>This Week Winners</h1>
<div className="container-last-win">
<Carousel autoPlay="true"
    interval={1500}
    showArrows={true}
    showThumbs={false}
    transitionTime={350}
    emulateTouch={true}
    swipeable={true}
    infiniteLoop={true}
    swipeScrollTolerance={5}
    verticalSwipe='standard'>
  {Week1.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week1.Created_Date).getDate()} <em>{new Date(Week1.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week1.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week1.profile_name}</p>
  {Week1.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week1.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>}
  {Week2.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week2.Created_Date).getDate()} <em>{new Date(Week2.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week2.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week2.profile_name}</p>
  {Week2.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week2.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>}
  {Week3.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week3.Created_Date).getDate()} <em>{new Date(Week3.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week3.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week3.profile_name}</p>
  {Week3.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week3.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>: 
   <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>}

  {Week4.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week4.Created_Date).getDate()} <em>{new Date(Week4.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week4.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week4.profile_name}</p>
  {Week4.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week4.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>}
  {Week5.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week5.Created_Date).getDate()} <em>{new Date(Week5.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week5.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week5.profile_name}</p>
  {Week5.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week5.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>}

  {Week6.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week6.Created_Date).getDate()} <em>{new Date(Week6.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week6.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week6.profile_name}</p>
  {Week6.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week6.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>}

  {Week7.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week7.Created_Date).getDate()} <em>{new Date(Week7.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week7.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week7.profile_name}</p>
  {Week7.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week7.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>}

  {Week8.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week8.Created_Date).getDate()} <em>{new Date(Week8.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week8.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week8.profile_name}</p>
  {Week8.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week8.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>}

  {Week9.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week9.Created_Date).getDate()} <em>{new Date(Week9.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week9.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week9.profile_name}</p>
  {Week9.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week9.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>}

  {Week10.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week10.Created_Date).getDate()} <em>{new Date(Week10.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week10.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week10.profile_name}</p>
  {Week10.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week10.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>}

  {Week11.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week11.Created_Date).getDate()} <em>{new Date(Week11.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week11.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week11.profile_name}</p>
  {Week11.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week11.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>}

  {Week12.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week12.Created_Date).getDate()} <em>{new Date(Week12.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week12.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week12.profile_name}</p>
  {Week12.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week12.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>}


{Week13.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week13.Created_Date).getDate()} <em>{new Date(Week13.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week13.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week13.profile_name}</p>
  {Week13.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week13.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Coolest</p>
</div>
</div>
</div>
</div>
</div>}

  {Week14.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week14.Created_Date).getDate()} <em>{new Date(Week5.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week14.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week14.profile_name}</p>
  {Week14.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week14.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cooler</p>
</div>
</div>
</div>
</div>
</div>}

  {Week15.length != 0 ?
  <div className="">
  <div className="container text-bottom">
  <div className="cards-list">
  <div className="card2 1">
  <div className="card_image2">
  <div>
   
  <p className="calendar-mob">{new Date(Week15.Created_Date).getDate()} <em>{new Date(Week15.Created_Date).toLocaleDateString('default', {month:'short'})}</em></p>  
  </div> 
  <img src={Week15.profile_imagePath} />     
  </div>
  <div className="card_title title-white">
  <p className="text-winner-list-win background-text-win">{Week15.profile_name}</p>
  {Week15.Rank_No==1?
  <p className="text-winner-cool-win">Coolest</p>:
  Week15.Rank_No==2?
  <p className="text-winner-cool-win"> Cooler</p>:
  <p className="text-winner-cool-win"> Cool</p>}
  </div>
  </div>
  </div>
  </div>
  </div>:
  <div className="">
<div className="container text-bottom">
<div className="cards-list">
<div className="card2 1">
<div className="card_image2">
<img src={pro}/>
</div>
<div className="card_title title-white">
<p className="text-winner-list-win background-text-win mw-text-size">No Winner</p>
<p className="text-winner-cool-win">Cool</p>
</div>
</div>
</div>
</div>
</div>}
</Carousel>

</div>
</div>
</div>

</body>
)
}
export default MobileWinnerApp;