import React from 'react';
import bg from '../Components/Assets/Images/M4.png'
function Footer(props) {
    return (
        <footer className='bottomfooter'>
        {/* <img src={bg} className='bg' id='footer'></img> */}
        <div className="social-container">
        <ul className="social-icons">
        <li className='socialli' title='FaceBook'><a href="https://www.facebook.com/Thats-Cool-101117014858325" target={'_blank'} className='sa'><i className="fa fa-facebook"></i></a></li>
        <li className='socialli' title='Instagram' ><a href="https://www.instagram.com/thatscool.in" className='sa' target={'_blank'}  id='ins' ><i className="fa fa-instagram"></i></a></li>
        <li className='socialli' title='YouTube'><a href="https://www.youtube.com/channel/UCeti67OjOLLn12yz4yu0U-w" target={'_blank'}  className='sa' id='youtube' ><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
        </ul>
        <div className="social-container2">
        <div>© Copyrights @Thatscool.in 2022. All Rights Reserved.</div>
        </div>
        </div> 
        </footer>
    );
}

export default Footer;