import React, { useState, useEffect } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Text.css'
import TC from '../Components/Assets/Images/TC3.png'
import pro from '../Components/Assets/Images/defaultprofilepic.png'
import cal from '../Components/Assets/Images/calender.png'
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Axios from 'axios';
import $ from 'jquery';
import { BASE_URL } from '../Components/BaseURL'
import AndroidQR from '../Components/Assets/Images/AndroidQR.png'
import IOSQR from '../Components/Assets/Images/IOSQR.png'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import cs from '../Components/Assets/Images/cs2.png'
// var mybutton = document.getElementById("arrow");

// When the user scrolls down 20px from the top of the document, show the button
// window.onscroll = function() {scrollFunction()};

// function scrollFunction() {
//   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
//     mybutton.style.display = "block";
//   } else {
//     mybutton.style.display = "none";
//   }
// }
$(function() {


  function determineScrollTarget()
  {
    var currentPos = $(window).scrollTop();
    
    //Add whatever stops you want to scroll to here.
    //Be sure to always order them from the top of the page to the bottom.
    var positions = [
      $('#content1').offset().top,
      $('#content2').offset().top,
      //Add new elements here.
      $(document).height() - $(window).height(), //Bottom of page
    ];
    
    
    var targetScrollPosition = 0;
    //Find the first position on the list that is lower than the current scroll position.
    for (var i = 0; i < positions.length; i++)
    {
      if (positions[i] > currentPos) 
      {
        targetScrollPosition = positions[i];
        break;
      }
     
    }
    
    return targetScrollPosition;
  }

  $('#arrow').click(function(e){
      e.preventDefault();
      $('html, body').animate({scrollTop: determineScrollTarget() }, 'fast');
  });
});
	
	
	// Menu
	$(window).on("resize", function () {
  var positionTop = window.innerHeight / 2;
  $('nav ul').css('top', positionTop);
  var marginTop = $('nav ul').height() / 2;
  $('nav ul').css('margin-top', -marginTop);
}).resize();

 
//   var text1 = [ "JUMP","B","THAT'S ",];
// var text2 = [ "IN","COOL","COOL",];
// var count = 3;
// setInterval(() => {
//   count--;
//   document.getElementById('text-1').innerHTML= text1[count];
//   document.getElementById('text-2-span').innerHTML= text2[count];

//   if(count == 0){
//     count =3;
//   }
// }, 7000);

function LandingPage() {
  
const [WinnerToday, SetwinnerToday] = useState([])
const [WinnerYesterday, SetwinnerYesterday] = useState([])
const [Mobile, SetMobile] = useState(false)
const [Todayone, setTodayone] = useState([]);
const [Todaytwo, setTodaytwo] = useState([]);
const [Todaythree, setTodaythree] = useState([]);
const [Yesterdayone, setYesterdayone] = useState([]);
const [Yesterdaytwo, setYesterdaytwo] = useState([]);
const [Yesterdaythree, setYesterdaythree] = useState([]);
const [Week, setWeek] = useState([]);

// const [Week1, setWeek1] = useState([]);
//   const [Week2, setWeek2] = useState([]);
//   const [Week3, setWeek3] = useState([]);
//   const [Week4, setWeek4] = useState([]);
//   const [Week5, setWeek5] = useState([]);
//   const [Week6, setWeek6] = useState([]);
//   const [Week7, setWeek7] = useState([]);
//   const [Week8, setWeek8] = useState([]);
//   const [Week9, setWeek9] = useState([]);
//   const [Week10, setWeek10] = useState([]);
//   const [Week11, setWeek11] = useState([]);
//   const [Week12, setWeek12] = useState([]);
//   const [Week13, setWeek13] = useState([]);
//   const [Week14, setWeek14] = useState([]);
//   const [Week15, setWeek15] = useState([]);

useEffect(() => {
  Axios({
    method: "GET",
    url: `${BASE_URL}Winner/winnertodayapp`
  }).then((resp)=>{
  SetwinnerToday(resp.data)
  if(resp.data.length>0){
    setTodayone(resp.data['0']);
  }
 
  if(resp.data.length>1){
    setTodaytwo(resp.data['1']);
  }

  if(resp.data.length>2){
    setTodaythree(resp.data['2']);
  }
  })
  Axios({
    method: "GET",
    url: `${BASE_URL}Winner/winneryesterday`
  }).then((response)=>{
  SetwinnerYesterday(response.data)
  if(response.data.length>0){
    setYesterdayone(response.data['0']);
  }
 
  if(response.data.length>1){
    setYesterdaytwo(response.data['1']);
  }

  if(response.data.length>2){
    setYesterdaythree(response.data['2']);
  }
  })
  

  Axios({
    method: "GET",
    url: `${BASE_URL}Winner/winnerweekly`
  }).then((resp)=>{
    setWeek(resp.data)
   
    // if(resp.data[0]!=null){
    //   setWeek1(resp.data[0])
    // }
    // if(resp.data[1]!=null){
    //   setWeek2(resp.data[1])
    // }
    // if(resp.data[2]!=null){
    //   setWeek3(resp.data[2])
    // }
    // if(resp.data[3]!=null){
    //   setWeek4(resp.data[3])
    // }
    // if(resp.data[4]!=null){
    //   setWeek5(resp.data[4])
    // }
    // if(resp.data[5]!=null){
    //   setWeek6(resp.data[5])
     
    // }
    // if(resp.data[6]!=null){
    //   setWeek7(resp.data[6])
    // }
    // if(resp.data[7]!=null){
    //   setWeek8(resp.data[7])
    // }
    // if(resp.data[8]!=null){
    //   setWeek9(resp.data[8]) 
    // }
    // if(resp.data[9]!=null){
    //   setWeek10(resp.data[9])
    // }
    // if(resp.data[10]!=null){
    //   setWeek11(resp.data[10])
    // }
    // if(resp.data[11]!=null){
    //   setWeek12(resp.data[11])
    // }
    // if(resp.data[12]!=null){
    //   setWeek13(resp.data[12])
    // }
    // if(resp.data[13]!=null){
    //   setWeek14(resp.data[13])
    // }
    // if(resp.data[14]!=null){
    //   setWeek15(resp.data[14])
    // }
  })
}, [])   

    return(
      <body>
        <Navbar/>
        <div class="center-container">
        <p class="center-text">We Are Coming Up With New Update</p>
        <img class="centered-image" src={cs} alt="Coming Soon" />
    </div>
        
      <Footer/>  
</body>
)
}
export default LandingPage;