import React, { useState, useEffect } from 'react'
import TC from '../Components/Assets/Images/TC3.png'
import bg from '../Components/Assets/Images/M4.png'
import {Link} from 'react-router-dom';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Cancellation() {
    return(
        <div >
    <Navbar/>
<div className="termbody">
<h3 className="thc">Cancellation and Refund Policy</h3>
<p className="termtext">Due to service providers in nature “NO REFUND”, “NO CANCELLATION” will be entertained once the Payment has been made.</p>
</div>



 {/* Footer Part */}
 <Footer/>
        </div>
    );
}
export default Cancellation