import React, { useState, useEffect } from 'react'
import '../Components/Assets/Images/M3.png'
import '../Components/Assets/Landingcss/FirstPage.css'
import TextTransition, { presets } from "react-text-transition";
import { useHistory, useNavigate } from "react-router-dom";
function FirstPage() {
    let history = useHistory();
    const [index, setIndex] = useState(0);
    const TEXTS = [
        " ",
        "B COOL",
        "JUMP IN",
        "THAT'S COOL"
        ];
useEffect(() => {
    setTimeout(() => { 
        history.push('/LandingPage');
         }, 7500)
    const intervalId = setInterval(() =>
setIndex(index => index + 1),
2000 // every 3 seconds
);
return () => clearTimeout(intervalId);

}, [])        
    return(
    <div className="image3">
    <div className="fpContainer">
    <div className="header2">
    <h1>
    <TextTransition text={ TEXTS[index % TEXTS.length] } springConfig={ presets.wobbly }/></h1>
    </div>
    </div>
    </div>
    )
}
export default FirstPage