import React, { useState, useEffect } from 'react'
import TC from '../Components/Assets/Images/TC3.png'
import bg from '../Components/Assets/Images/M4.png'
import {Link} from 'react-router-dom';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Terms() {
    return(
    <div>
    <Navbar/>
    <div className="termbody">
    <h3 className="thc">Terms and Conditions</h3>
    <p className="termtext">These Website Standard Terms and Conditions contained herein on this webpage, shall govern your
use of this website, including all pages within this website (collectively referred to herein below as this
“Website”). These Terms apply in full force and effect to your use of this Website and by using this
Website, you expressly accept all terms and conditions contained herein in full. You must not use this
Website, if you have any objection to any of these Website Standard Terms and Conditions. KaiDP
Project Advisors Pvt Ltd & KaiDP Technolgies LLP (ThatsCool) offers you access to its interactive online
websites, applications and services. These Terms of Use, together with our ("Privacy Policy") and any
additional terms which might apply to certain products or services, govern your use of our websites
("Website") or any of our mobile widgets or other applications ("Applications") – together, our
"Services". Our Services include www.ThatsCool.in and all other locations on which we place these
Terms of Use, as well as all mobile and tablet applications. PLEASE READ THESE TERMS OF USE
CAREFULLY BEFORE USING OUR SERVICES. BY USING ANY OF OUR SERVICES (OTHER THAN TO READ
THESE TERMS OF USE FOR THE FIRST TIME) YOU ARE AGREEING TO COMPLY WITH THESE TERMS OF
USE, WHICH MAY CHANGE FROM TIME TO TIME AS SET FORTH IN SECTION TITLED "AMENDMENTS;
ADDITIONAL TERMS". IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, DO NOT USE
OUR SERVICES. 
</p>
    <h4 className="termhead-3">Legal Status</h4>
    <p className="termtext">KaiDP Project Advisors Pvt Ltd was incorporated under the Companies Act, 2013 on 13/05/2015. &
KaiDP Technolgies LLP, an LLP registered under the provisions of the Limited Liability Partnership Act,
2008 (LLP Act) on 27/07/2022. Consent to Electronic Disclosures Because ThatsCool Services are
provided electronically, you must consent to ThatsCool providing information if you would like to
proceed. This notice must be read carefully, and a copy may be retained for future reference. Should
you consent to receive information electronically the following disclosures will be sent to you via
electronic mail or our website: </p>
    <ol className="termlist">
        <li>The ThatsCool Terms and Conditions, including legal disclosures.</li>
        <li>Any changes made in the future regarding ThatsCool Terms and Conditions.</li>
        <li>Account information</li>
        <li>ThatsCool Privacy Policy.</li>
        <li>All other legal notices and communications and/or disclosures.</li>
    </ol>
    <p className="termtext">In order to receive this information through an electronic medium, you must have a computer or mobile smart phone with internet access and the ability to receive e-mail. Please be advised that some web browsers may not be supported.</p>
    <h4 className="termhead-3">In registering for ThatsCool, you:</h4>
    <ol className="termlist">
    <li>Agree to receive information and disclosures electronically on this website and/or via email and confirm that you will download and print any disclosures for your own personal
records. </li>
    <li>Confirm that you have the capacity to receive information that is provided electronically
at this website and via e-mail. </li>
    <li>Authorize electronic delivery of any and all communications and disclosures regarding
ThatsCool services on this website and via e-mail and providing your consent to receive
electronic communications. 
</li>
    <li>Agree to receive e-mails regarding the ThatsCool services. </li>
    </ol>
    <p className="termtext">Notices shall be considered as received within 24 hours from the time it has been posted on the
website or e-mailed to you unless a notice has been received that the email was not delivered. 
</p>
    <h4 className="termhead-3">ThatsCool Mobile Applications</h4>
    <p className="termtext">ThatsCool’s mobile application allows users to access many of ThatsCool services through a mobile
device. If you use the application you are responsible for any fees that your phone service provider
charges for SMS, data, etc. ThatsCool is not affiliated with any phone service providers. </p>
    <h4 className="termhead-3">Identity Authentication</h4>
    <p className="termtext">In order to remain compliant with the laws established by the Republic of India, ThatsCool reserves the
right to obtain, verify and sometimes store personal information. ThatsCool is not a Money Services
Business; however as required by law ThatsCool must store certain pieces of customer information and
cooperate with law enforcement to help the government fight terrorism and money laundering.
ThatsCool may also share this information with some strategic partners.</p>
    <h4 className="termhead-3">Intellectual Property</h4>
    <p className="termtext">Our Services and associated content (and any derivative works or enhancements of the same)
including, but not limited to, all text, illustrations, files, images, software, scripts, graphics, photos,
sounds, music, videos, information, content, materials, products, services, URLs, technology,
documentation, and interactive features included with or available through our Services (collectively,
the "Service Content") and all intellectual property rights to the same are owned by us, our licensors,
or both. Additionally, all trademarks, service marks, trade names and trade dress that may appear in
our Services are owned by us, our licensors, or identified third parties. Except for the limited use rights
granted to you in these Terms of Use, you shall not acquire any right, title or interest in our Services
or any Service Content. Any rights not expressly granted in these Terms of Use are expressly reserved.
Any event created by a Campaign user, shall be the sole intellectual property of such campaign user
with the exception of any trademark, service marks, trade names and trade dress that are the sole
intellectual property of the company. Other than content you own, which you may have opted to
include on this Website, under these Terms, KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP
(ThatsCool.in) and/or its licensors own all rights to the intellectual property and material contained in
this Website, and all such rights are reserved. You are granted a limited license only, subject to the
restrictions provided in these Terms, for purposes of viewing the material contained on this Website. </p>
    <h4 className="termhead-3">Restrictions</h4>
    <h5 className="termhead-3">You are expressly and emphatically restricted from all of the following:</h5>
    <ol className="termlist">
<li>publishing any Website material in any media;</li>
<li>selling, sublicensing and/or otherwise commercializing any Website material.</li>
<li>publicly performing and/or showing any Website material. 
</li>
<li>using this Website in any way that is, or may be, damaging to this Website</li>
<li>using this Website in any way that impacts user access to this Website. </li>
<li>using this Website contrary to applicable laws and regulations, or in a way that causes, or
may cause, harm to the Website, or to any person or business entity.</li>
<li>engaging in any data mining, data harvesting, data extracting or any other similar activity
in relation to this Website, or while using this Website. 
</li>
<li>using this Website to engage in any advertising or marketing. </li>
</ol>
<p className="termtext">Certain areas of this Website are restricted from access by you and KaiDP Project Advisors Pvt Ltd &
KaiDP Technolgies LLP (ThatsCool.in) may further restrict access by you to any areas of this Website,
at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website
are confidential and you must maintain confidentiality of such information. </p>
<h4 className="termhead-3">Access and Use</h4>
<p className="termtext">We may offer our Services at no charge. In all instances, our Services are not being sold to you; rather,
you are being granted or purchasing a limited license to use our Services. In addition, unless we
specifically tell you otherwise, the use of any of our paid Services does not transfer across operating
systems and/or different equipment (e.g., mobile devices, computers, etc.). In connection with your
use of our website and/or mobile/tablet applications, your account, or the services, or in the course of
your interactions with ThatsCool, a User or a third party, you will not: </p>
<ol className="termlist">
<li>Violate any law, statute, ordinance, or regulation (for example, those governing financial
services, consumer protections, unfair competition, anti-discrimination or false
advertising); 
</li>
<li>Act in a manner that is defamatory, trade libelous, unlawfully threatening or unlawfully
harassing. 
</li>
<li>Provide false, inaccurate or misleading Information. 
</li>
<li>Send or receive what we reasonably believe to be potentially fraudulent funds.</li>
<li>Refuse to cooperate in an investigation or provide confirmation of your identity or any
Information you provide to us. </li>
<li>Use an anonymous proxy. 
</li>
<li>Control an Account that is linked to another Account that has engaged in any of these
Restricted Activities. Conduct your business or use the Services in a manner that results
in or may result in complaints, Disputes, Claims, Reversals, Chargebacks, fees, fines,
penalties and other liability to ThatsCool, a User, a third party or you. </li>
<li>Take any action that imposes an unreasonable or disproportionately large load on our
infrastructure. 
</li>
<li>Facilitate any viruses, Trojan horses, worms or other computer programming routines that
may damage, detrimentally interfere with, surreptitiously intercept or expropriate any
system, data or Information. </li>
<li>Use any robot, spider, other automatic device, or manual process to monitor or copy our
website without our prior written permission. </li>
<li>Use any device, software or routine to bypass our robot exclusion headers, or interfere or
attempt to interfere, with our website or the Services. 
</li>
<li>Take any action that may cause us to lose any of the services from our internet service
providers, payment processors, or other suppliers. 
</li>
<li>Transaction Fee – a fee in connection with certain transactions you may enter using the
funds via our Service. 
</li>
</ol>
<p className="termtext">When using our Services, you agree to comply with all applicable central, state, and local laws
including, without limitation, copyright law. Except as expressly permitted in these Terms of Use or as
we may otherwise permit, you may not use, reproduce, duplicate, distribute, create derivative works
based upon, publicly display, publicly perform, publish, transmit, or otherwise exploit Service Content
for any purpose whatsoever without obtaining prior written consent from us or, in the case third-party
content, its applicable owner. In certain instances, we may suggest, ask or otherwise permit you to
download, install or print Service Content. In such a case, you may do so only in the manner authorized
and for your non-commercial use only. You acknowledge that you do not acquire any ownership rights
by downloading, installing or printing Service Content. Furthermore, except as expressly permitted in
these Terms of Use, you may not: 
</p>
<ol className="termlist">
<li>remove, alter, cover, or distort any copyright, trademark, or other proprietary rights notice
we include in or through our Services or Service Content. </li>
<li>circumvent, disable or otherwise interfere with our security-related features including,
without limitation, any features that prevent or restrict the use of or copying of any
software or other Service Content.</li>
<li>use an automatic device (such as a robot or spider) or manual process to copy or "scrape"
the Website or Service Content for any purpose without our express written permission. </li>
<li>collect or harvest any personally identifiable information from our Services including,
without limitation, usernames, passwords, email addresses.</li>
<li>solicit other users to join or become members of any commercial online service or other
organization without our prior written approval. </li>
<li> attempt to or interfere with the proper working of our Services or impair, overburden, or
disable the same. </li>
<li>decompile, reverse engineer, or disassemble any portion of our software or other Service
Content, or our Services. 
</li>
<li>use network-monitoring software to determine architecture of or extract usage data from
our Services. 
</li>
<li>encourage conduct that violates any local, state or federal law, either civil or criminal, or
impersonate another user, person, or entity (e.g., using another person’s Membership (as
defined below).</li>
<li>engage in any conduct that restricts or inhibits any other user from using or enjoying our
Services. 
</li>
</ol>
<p className="termtext">You agree to fully cooperate with us to investigate any suspected or actual activity that is in breach of
these Terms of Use. ThatsCool has certain limits in place in order to reduce the risk of fraud and
chargebacks within the system. By using ThatsCool and its services you agree that you abide by the
following parameters: 
</p>
<h4 className="termhead-3">Your Content</h4>
<p className="termtext">In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video, text,
images or other material you choose to display on this Website. With respect to Your Content, by
displaying it, you grant KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in) a nonexclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish,
translate and distribute it in any and all media. </p>
<p className="termtext">Your Content must be your own and must not be infringing on any third party’s rights. KaiDP Project
Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in) reserves the right to remove any of Your
Content from this Website at any time, and for any reason, without notice. 
</p>
<p className="termtext">We permit users to post, create, transmit through, or otherwise make available through our Services
(collectively, "submit") messages, text, illustrations, data, files, images, graphics, photos, comments, sounds, music, videos,
information, content, and/or other materials ("User Content"). Subject to the rights and license you
grant herein, you retain all right, title and interest in your User Content. We do not guarantee any
confidentiality with respect to User Content even if it is not published through our Services. It is solely
your responsibility to monitor and protect any intellectual property rights that you may have in your
User Content, and we do not accept any responsibility for the same.  
</p>
<p className="termtext">You shall not submit any User Content protected by copyright, trademark, patent, trade secret, moral
right, or other intellectual property or proprietary right without the express permission of the owner
of the respective right. You are solely liable for any damage resulting from your failure to obtain such
permission or from any other harm resulting from User Content that you submit </p>
<h5 className="termhead-3">You represent, warrant, and covenant that you will not submit any User Content that:</h5>
<ol className="termlist">
<li>violates or infringes in any way upon the rights of others, including, but not limited to,
any copyright, trademark, patent, trade secret, moral right, or other intellectual property
or proprietary right of any person or entity. 
</li>
<li>impersonates another or is unlawful, threatening, abusive, libelous, defamatory, invasive
of privacy or publicity rights, vulgar, obscene, profane, pornographic, or otherwise
objectionable. 
</li>
<li>encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law.</li>
<li>is an advertisement for goods or services or a solicitation of funds</li>
<li>includes personal information such as messages which identify phone numbers, social
security numbers, account numbers, addresses, or employer references. </li>
<li>contains a formula, instruction, or advice that could cause harm or injury; or </li>
<li>is a chain letter of any kind.</li>
</ol>
<p className="termtext">Moreover, any conduct by a user that in our sole discretion restricts or inhibits any other user from
using or enjoying our Services will not be permitted. </p>
<p className="termtext">By submitting User Content to us, simultaneously with such posting you automatically grant, or warrant
that the owner has expressly granted, to us a worldwide, royalty-free, perpetual, irrevocable, nonexclusive, fully sublicensable, and transferable right and license to use, reproduce, distribute, create
derivative works based upon (including, without limitation, translations), publicly display, publicly
perform, transmit, and publish the User Content (in whole or in part) as we, in our sole discretion, deem
appropriate including, without limitation, (1) in connection with our business; and (2) in connection
with the businesses of our successors, parents, subsidiaries, and their related companies. We may
exercise this grant in any format, media or technology now known or later developed for the full term
of any copyright that may exist in such User Content. Furthermore, you also grant other users’
permission to access your User Content and to use, reproduce, distribute, create derivative works
based upon, publicly display, publicly perform, transmit, and publish your User Content for personal,
non-commercial use as permitted by the functionality of our Services and these Terms of Use.
Notwithstanding the foregoing, you waive any and all claims you may now or later have in any
jurisdiction to so-called "moral rights" with respect to the User Content.</p>
<p className="termtext">By submitting User Content, you also grant us the right, but not the obligation to use your biographical
information including, without limitation, your name and geographical location in connection with
broadcast, print, online, or other use or publication of your User Content; provided, however, that all
such uses (including use of data on playlists, genre(s) and other User Content we automatically collect)
will be consistent with the terms of our Privacy Policy. 
</p>
<p className="termtext">We reserve the right to display advertisements in connection with your User Content and to use your
User Content for advertising and promotional purposes. You acknowledge and agree that your User
Content may be included on the websites and advertising networks of our distribution partners and
third-party service providers (including their downstream users). </p>
<p className="termtext">We have the right, but not the obligation, to monitor User Content. We have the right in our sole
discretion and for any reason whatsoever to edit, refuse to post, remove, or disable access to any User
Content</p>
<h4 className="termhead-3">User Registration</h4>
<p className="termtext">In order to access or use some features of our Services, you may have to become a registered user. If
you are under the age of 18, then you are not permitted to register as a user or otherwise submit
personal information. If you become a registered user, you will provide true, accurate and complete
registration information and, if such information changes, you will promptly update the relevant
registration information. During registration, you will create a username and password (a
"Membership"). You are responsible for safeguarding and maintaining the confidentiality of your
Membership. You are solely responsible for the activity that occurs under your Membership, whether
or not you have authorized the activity. You agree to Contact Us immediately if you become aware of
any breach of security or unauthorized use of your Membership. </p>
<h4 className="termhead-3">Service Content & Third-Party Links</h4>
<p className="termtext">We provide our Services including, without limitation, Service Content for entertainment and/or
promotional purposes only. You may not rely on any information and opinions expressed through any
of our Services for any other purpose. In all instances, it is your responsibility to evaluate the accuracy,
timeliness, completeness, or usefulness of any Service Content. Under no circumstances will we be
liable for any loss or damage caused by your reliance on any Service Content. In many instances,
Service Content will include content posted by a third-party or will represent the opinions and
judgments of a third-party. We do not endorse, warrant and are not responsible for the accuracy,
timeliness, completeness, or reliability of any opinion, advice, or statement offered through our
Services by anyone other than our authorized employees or spokespersons while acting in their official
capacities. Our Services may link or contain links to other websites maintained by third parties. We do
not operate or control, in any respect, or necessarily endorse the content found on these third-party
websites. You assume sole responsibility for your use of third-party links. We are not responsible for
any content posted on third-party websites or liable to you for any loss or damage of any sort incurred
as a result of your dealings with any third-party or their website. </p>
<h4 className="termhead-3">Indemnification</h4>
<p className="termtext">You agree to indemnify and hold harmless KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies
LLP(ThatsCool.in) and its officers, directors, employees, parents, partners, successors, agents,
distribution partners, affiliates, subsidiaries, and their related companies from and against any and all
claims, liabilities, losses, damages, obligations, costs and expenses (including reasonable attorneys’
fees and costs) arising out of, related to, or that may arise in connection with: (i) your use of our
Services; (ii) User Content provided by you or through use of your Membership; (iii) any actual or
alleged violation or breach by you of these Terms of Use; (iv) any actual or alleged breach of any
representation, warranty, or covenant that you have made to us; or (v) your acts or omissions. You
agree to cooperate fully with us in the defense of any claim that is the subject of your obligations
hereunder. 
</p>
<h4 className="termhead-3">Termination</h4>
<p className="termtext">We reserve the right in our sole discretion and at any time to terminate or suspend your Membership
and/or block your use of our Services for any reason including, without limitation if you have failed to
comply with the letter and spirit of these Terms of Use. You agree that KaiDP Project Advisors Pvt Ltd
& KaiDP Technolgies LLP(ThatsCool.in) is not liable to you or any third party for any termination or
suspension of your Membership or for blocking your use of our Services. Any suspension or
termination shall not affect your obligations to us under these Terms of Use. The provisions of these
Terms of Use which by their nature should survive the suspension or termination of your Membership
or these Terms of Use shall survive including, but not limited to the rights and licenses that you have
granted hereunder, indemnities, releases, disclaimers, limitations on liability, provisions related to
choose of law, and all of the provisions in the Section titled "MISCELLANEOUS". </p>
<h4 className="termhead-3">No warranties</h4>
<p className="termtext">This Website is provided “as is,” with all faults, and KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies
LLP (ThatsCool.in) makes no express or implied representations or warranties, of any kind related to
this Website or the materials contained on this Website. Additionally, nothing contained on this
Website shall be construed as providing consult or advice to you. </p>
<h4 className="termhead-3">Limitation of liability</h4>
<p className="termtext">In no event shall KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in), nor any of its
officers, directors and employees, be liable to you for anything arising out of or in any way connected
with your use of this Website, whether such liability is under contract, tort or otherwise, and KaiDP
Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in), including its officers, directors and
employees shall not be liable for any indirect, consequential or special liability arising out of or in any
way related to your use of this Website</p>
<h4 className="termhead-3">Indemnification</h4>
<p className="termtext"> You hereby indemnify to the fullest extent KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies
LLP(ThatsCool.in) from and against any and all liabilities, costs, demands, causes of action, damages
and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach
of any of the provisions of these Terms. </p>
<h4 className="termhead-3">Severability</h4>
<p className="termtext">If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such
unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and
such provisions shall be deleted without affecting the remaining provisions herein. 
</p>
<p className="termtext">Variation of Terms KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in) is permitted
to revise these Terms at any time as it sees fit, and by using this Website you are expected to review
such Terms on a regular basis to ensure you understand all terms and conditions governing use of this
Website. 

</p>
<h4 className="termhead-3">Assignment</h4>
<p className="termtext">KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in) shall be permitted to assign,
transfer, and subcontract its rights and/or obligations under these Terms without any notification or
consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your
rights and/or obligations under these Terms. </p>
<h4 className="termhead-3">Entire Agreement</h4>
<p className="termtext"> These Terms, including any legal notices and disclaimers contained on this Website, constitute the
entire agreement between KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in) and
you in relation to your use of this Website, and supersede all prior agreements and understandings
with respect to the same. </p>
<h4 className="termhead-3">Governing Law & Jurisdiction</h4>
<p className="termtext">These Terms will be governed by and construed in accordance with the laws of the State of Karnataka,
and you submit to the non-exclusive jurisdiction of the state and federal courts located in Karnataka
for the resolution of any disputes. </p>
<h4 className="termhead-3">Exceptions</h4>
<p className="termtext">Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be
unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit KaiDP Project
Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in) liability in respect of any: </p>
<ol className="termlist">
<li>Death or personal injury caused by KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies
LLP(ThatsCool.in) negligence. </li>
<li>Fraud or fraudulent misrepresentation on the part of KaiDP Project Advisors Pvt Ltd &
KaiDP Technolgies LLP(ThatsCool.in); or 
</li>
<li>Matter which it would be illegal or unlawful for KaiDP Project Advisors Pvt Ltd & KaiDP
Technolgies LLP(ThatsCool.in) to exclude or limit, or to attempt or purport to exclude or
limit, its liability. 
</li>
</ol>
<h4 className="termhead-3">Reasonableness</h4>
<p className="termtext">By using this website, you agree that the exclusions and limitations of liability set out in this website
disclaimer are reasonable. If you do not think they are reasonable, you must not use this website. </p>
<h4 className="termhead-3">Other parties</h4>
<p className="termtext">You accept that, as a limited liability entity, KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies
LLP(ThatsCool.in) has an interest in limiting the personal liability of its officers and employees. You
agree that you will not bring any claim personally against KaiDP Project Advisors Pvt Ltd & KaiDP
Technolgies LLP(ThatsCool.in) officers or employees in respect of any losses you suffer in connection
with the website. Without prejudice to the foregoing paragraph, you agree that the limitations of
warranties and liability set out in this website disclaimer will protect KaiDP Project Advisors Pvt Ltd &
KaiDP Technolgies LLP(ThatsCool.in) officers, employees, agents, subsidiaries, successors, assigns and
sub-contractors as well as KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in). </p>
<h4 className="termhead-3">Amendment; Additional Terms</h4>
<p className="termtext">We reserve the right in our sole discretion and at any time and for any reason, to modify or discontinue
any aspect or feature of our Services or to modify these Terms of Use. In addition, we reserve the right
to provide you with operating rules or additional terms that may govern your use of our Services
generally, unique of our Services, or both ("Additional Terms"). Any Additional Terms that we may
provide to you will be incorporated by reference into these Terms of Use. To the extent any Additional
Terms conflict with these Terms of Use, the Additional Terms will control. 
</p>
<p className="termtext">Modifications to these Terms of Use or Additional Terms will be effective immediately upon notice,
either by posting on the Website, notification by email or through any of our Applications. It is your
responsibility to review the Terms of Use from time to time for any changes or Additional Terms. Your
access and use of our Services following any modification of these Terms of Use or the provision of
Additional Terms will signify your assent to and acceptance of the same. If you object to any
subsequent revision to the Terms of Use or to any Additional Terms, immediately discontinue use of
our Services and, if applicable, terminate your Membership 
</p>
<h4 className="termhead-3">Miscellaneous</h4>
<p className="termtext">No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any
preceding or subsequent breach or default. The section headings used herein are for convenience only
and shall not be given any legal import. 
</p>
<p className="termtext">Except where specifically stated otherwise, if any part of these Terms of Use is unlawful or
unenforceable for any reason, we both agree that only that part of the Terms of Use shall be stricken
and that the remaining terms in the Terms of Use shall not be affected. </p>
<p className="termtext">These Terms of Use (including the Privacy Policy and any Additional Terms incorporated by reference)
constitute the entire agreement of the parties with respect to the subject matter hereof and supersede
all previous written or oral agreements between us with respect to such subject matter. 
</p>
<p className="termtext">You may not assign these Terms of Use or assign any rights or delegate any obligations hereunder, in
whole or in part, without our prior written consent. Any such purported assignment or delegation by
you without the appropriate prior written consent will be null and void and of no force and effect. We
may assign these Terms of Use or any rights hereunder without your consent and without notice. </p>
<p className="termtext"> This website uses cookies. By using this website and agreeing to these terms and conditions, you
consent to our KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in)'s use of cookies
in accordance with the terms of KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP(ThatsCool.in)'s
privacy policy/cookies policy. </p>
<h4 className="termhead-3">Copyright</h4>
<p className="termtext">This website and its content is copyright of KaiDP Project Advisors Pvt Ltd & KaiDP Technolgies LLP©
2022. All rights reserved 
</p>
<p className="termtext">Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following:</p>
<ol className="termlist">
<li>You may print or download to a local hard disk extracts for your personal and non-commercial use only</li>
<li>You may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material</li>
</ol>
<p className="termtext">You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</p>
    </div>
    {/* Footer Part */}
    <Footer/>
    </div>
    );
}
export default Terms