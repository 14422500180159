import React from 'react';
import TC from '../Components/Assets/Images/TC3.png'
import bg from '../Components/Assets/Images/M4.png'
import {Link} from 'react-router-dom';
import '../Components/Assets/Landingcss/Navbar.css'
function Navbar() {
    return (
      <div className="bg-image bgp">
      <div id="loader-wrapper">
<div id="loader"></div>
<div className="loader-section section-left"></div>
<div className="loader-section section-right"></div>
</div>
       <a id="top"/>
      {/*Header Part */}
      <Link to='/LandingPage'>
     <img src={TC} className="logo"/>
     </Link>
    <div className="header-bottom">
    
    <nav className="navbars">
       
    <img src={bg} height='300%' width='150%' className='bg'></img>
   
    </nav>
    {/* <h1 className="header" id='cool'>That's Cool</h1> */}
       
    <h1 className="header" id="cool">
 <span>T</span>
 <span>h</span>
 <span>a</span>
 <span>t'</span>
 <span>s</span>
 <span>&nbsp;</span>
 <span>C</span>
 <span>o</span>
 <span>o</span>
 <span>l</span>
 </h1>

    </div>
{/* 
<div className="menu-outer">
   <div className="menu-icon">
       <div className="bar"></div>
       <div className="bar"></div>
       <div className="bar"></div>
   </div>
   <nav className='navmenu'>
   <ul>
       <li><Link to='/LandingPage' id="Navitem">Home</Link></li>
       <li><Link to='/Aboutus' id="Navitem">About Us</Link></li>
       <li><Link to='/Terms' id="Navitem">Terms and Condition</Link></li>
       <li><Link to='/Privacy' id="Navitem">Privacy Policy</Link></li>
       <li><Link to='/HowItWorks' id="Navitem">How it Works</Link></li>
       </ul>
  </nav>
</div>
<span className="menu-close">
   <div className="menu-icon">
       <div className="bar"></div>
       <div className="bar"></div>
   </div>
</span> */}
</div>
    );
}

export default Navbar;