import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  HashRouter
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import FirstPage from './Screen/FirstPage';
import Aboutus from './Screen/Aboutus';
import LandingPage from './Screen/LandingPage';
import Privacy from './Screen/Privacy';
import Faq from './Screen/Faq';
import MobileWinnerApp from './Screen/MobileWinnerApp';
import ProfileTesting from './Screen/ProfileTesting';
import MobileOceanPage from './Screen/MobileOceanPage';
import Terms from './Screen/Terms';
import Cancellation from './Screen/Cancellation';
import VerifyOTPAPP from './Screen/VerifyOTPApp';
import ForgetPasswordApp from './Screen/ForgetPasswordApp';
import Tcwalletadmin from './Screen/Tcwalletadmin';
import Tcwalletpage from './Screen/Tcwalletpage';
import Support from './Screen/Support';
import Report from './Screen/Report'
import HowItWorks from './Screen/HowItWorks'
import Unfollow from './Screen/Unfollow'
import Winner from './Screen/Winner'
// import { HashRouter } from 'react-router-dom/cjs/react-router-dom.min';

ReactDOM.render(
  <HashRouter>
    <Switch>
    <Route exact path="/" component={FirstPage} />
    <Route path="/LandingPage" component={LandingPage} />
    <Route path="/Privacy" component={Privacy} />
    <Route path="/Faq" component={Faq} />
    <Route path="/MobileWinnerApp" component={MobileWinnerApp} />
    <Route path="/ProfileTesting" component={ProfileTesting} />
    <Route path="/MobileOceanPage" component={MobileOceanPage} />
    <Route path="/Aboutus" component={Aboutus} />
    <Route path="/Terms" component={Terms} />
    <Route path="/Cancellation" component={Cancellation} />
    <Route path="/VerifyOTPAPP" component={VerifyOTPAPP} />
    <Route path="/ForgetPasswordApp" component={ForgetPasswordApp} />
    <Route path="/Tcwalletadmin" component={Tcwalletadmin} />
    <Route path="/Tcwalletpage" component={Tcwalletpage} />
    <Route path="/Support" component={Support} />
    <Route path="/Report" component={Report} />
    <Route path="/HowItWorks" component={HowItWorks} />
    <Route path="/Unfollow" component={Unfollow} />
    <Route path="/Winner" component={Winner} />
    <Route path="*">
            <FirstPage />
          </Route>
  </Switch>
</HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();