import react from 'react'
import '../Components/Assets/Landingcss/Winner.css'
import like from '../Components/Assets/Images/Like.png'
import Share from '../Components/Assets/Images/share.png'
import pro from '../Components/Assets/Images/profile_team-4.jpg'

function MobileOceanPage() {
    return(
        <body>
        <div>

        <div className="profile">
        <img src={pro}/>
        </div>
        <div className="profile_Name">
        <p>Name</p>
        <p className="fas fa-ellipsis-h elli"></p>
        </div>
        <p className="profile-title">Title here</p>

        <div className="like">
        <img className="like-btn-cool"  src={like}/>
        <img className="like-btn-share"  src={Share}/>
        </div>

        <div className="profile">
        <img src={pro}/>    
        </div>
        <div className="profile_Name">
        <p>Dhineshbabu</p>
        <p className="fas fa-ellipsis-h elli"></p>
        </div>
        <p className="profile-title">Title here</p>

        <div className="like">
        <img className="like-btn-cool" src={like}/>
        <img className="like-btn-share"  src={Share}/>
        </div>


        <div className="profile">
        <img src={pro}/>    
        </div>
        <div className="profile_Name">
        <p>Dhineshbabu</p>
        <p className="fas fa-ellipsis-h elli"></p>
        </div>
        <p className="profile-title">Title here</p>
        <video className="profile-upload-img" src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4" controls muted autoPlay={"autoplay"} preLoad="auto" loop>something</video>
        <div className="like">
        <img className="like-btn-cool" src={like}/>
        <img className="like-btn-share"  src={Share}/>
        </div>

        </div>
        </body>
    );
}
export default MobileOceanPage