import React, { useState, useEffect } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import TC from '../Components/Assets/Images/TC3.png'
import {Link,useHistory,useNavigate } from 'react-router-dom';
import '../Components/Assets/Landingcss/Text.css'
import { BASE_URL } from '../Components/BaseURL';
function VerifyOTPApp(props) {
    const [Error, setError] = useState("");
    
    let history = useHistory();

    const submit=(event)=> {
        event.preventDefault();         
         let secretcode = props.location.state.data.OTP;
         const OTP=JSON.parse(secretcode);
       
       if(document.getElementById("OTP").value==""){ 
            
             setError("! OTP must be filed")
             return false;
         }
         if(OTP!=document.getElementById("OTP").value){
          setError("! Enter Correct OTP")
             
             return false;
  
         }
         if(document.getElementById("password").value==""){
         
          setError("! New Secret code must be filed")
           
            return false;
        }
        var str =document.getElementById("password").value;
        if (str.match(/[a-z]/g) && str.match(/[A-Z]/g) && str.match(/[0-9]/g) && str.match(/[^a-zA-Z\d]/g) && str.length >= 8) {
          
          setError("")  
       }
       else{
           setError("! Secret code must contain at least 8 characters, one uppercase, one number and one special case character")    
  
        return false;
       }
       if(document.getElementById("cpassword").value==""){
     
        setError("! Confirm Secret code must be filed")  
        return false;
    }
        if(document.getElementById("cpassword").value!=document.getElementById("password").value){
          ; 
          setError("! Secret code and Confirm Secret code must be same") 
            return false;
        }
        setError("")  
      
        const data = new FormData(event.target);
      
          fetch(`${BASE_URL}Profile/verifyotp`, {
            method: 'POST',            
           
            body: data       
          }) 
           
          }
    return(
        <div className="forget">
  <div className="forgetform">
    <form className="forget-form" method="post" onSubmit={submit}  autoComplete="off">
     <img className="Logo" src={TC}></img>
     <h3 className='title'>One Time Secret Code</h3>
     <p className='forgoterror'>{Error}</p>
          <input type="text" name="OTP"  id="OTP" placeholder="Enter Your OTP"  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}/> 
          <input name="NewPassword"   placeholder="New Secret code"   id="password" type="password"/> 
          <input  placeholder="Confirm Secret code"  id="cpassword"   type="password"/> 
       
      <button>Submit</button>
    </form>  
  </div>
</div>
    )
}
export default VerifyOTPApp;