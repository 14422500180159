import React, { useState, useEffect } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Text.css'
import TC from '../Components/Assets/Images/TC3.png'
import log from '../Components/Assets/Images/logout.png'
import edit from '../Components/Assets/Images/edit.png'
import tick from '../Components/Assets/Images/tick.png'
import '../Components/Assets/Landingcss/Wallet.css';
import ReactPaginate from "react-paginate";
import moment from 'moment'
import axios from 'axios';
import { Table } from 'react-bootstrap';
import { useHistory, useNavigate } from "react-router-dom";
import bg from '../Components/Assets/Images/M4.png'
import Loading from '../Components/Assets/Images/Spinner.gif'
import Loads from '../Components/Assets/Images/load.gif'
import 'font-awesome/css/font-awesome.min.css';
import { Link } from "react-router-dom";
import Footer from '../Components/Footer'
import { BASE_URL } from '../Components/BaseURL'
function Tcwalletpage() {
  const[actiondisp, setActiondisp] = useState(false)
  const[pending, setpending] = useState([])
    const [search, setSearch] = useState('')
    const [Bname, setBname] = useState('')
    const [Upiid, setUpiid] = useState('')
    const [id, setid] = useState(0)
    const [Bankname, setBankname] = useState('')
    const [pagenumber,setpagenumber]=useState(0)
    const [data,setData]=useState([])
    const [allposts,setallposts]=useState([])
    const [getstockfull,Setgetstockfull] = useState([])
    const [Load,setLoad] = useState(false)
    const [isModalOpen, setModalIsOpen] = useState(false);
    const [comp, setComp] = useState(true);
    const pagepercount=5;
    const pagevisited=pagenumber*pagepercount;
    const pagelimit=Math.ceil(getstockfull.length/pagepercount)
    let history = useHistory();
    function changePage({selected}){
    setpagenumber(selected)
    }

    function toggleModal(p, a, b, d) {
      setModalIsOpen(!isModalOpen);
      setBname(p)
      setUpiid(a)
      setBankname(b)
      setid(d)
    }

    useEffect(() => {   
    setTimeout(() => {setLoad(true);}, 2000);
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    axios.get(`${BASE_URL}Admin/Total_TransactionHistory`,{ headers: {Authorization: "Bearer " + accessToken} })
    .then(response => {
      if(response.data[0]!=null){
            setpending(response.data[0])
           
          }
     
    setData(response.data.slice(pagevisited,pagevisited+pagepercount))
    Setgetstockfull(response.data)
    setallposts(response.data)
    })
    }, [pagenumber]);
   
    const SeachFunctionality = e => {
      // filter post list by title using onKeyUp function`
      const datas = allposts.filter(item =>
      item.Bank_HolderName.toLowerCase().includes(e.target.value.toLowerCase()) || item.UPI_Id.toLowerCase().includes(e.target.value.toLowerCase()) 
      )
        setData(datas);       
  };
  const LogOff=()=> {
    var x = window.confirm("Are you sure want to logout?");
    if (x){
    localStorage.clear();
        var page= {
            pathname: '/Tcwalletadmin'
             }
           history.push(page);
          }
          else{
             return false;
         }   
          }
          function comdisp() {
            setComp(false)
            
            setActiondisp(true)
          }
          function pendisp() {
            setComp(true)
            setActiondisp(false)
          }  
          let data2=localStorage.getItem('user')
      if(data2!=null){
    return(
      <>
      {!Load? <img className="loadings" src={Loads} /> 
        :
      <body className="">
        <div className="bg-image bgp">
        {isModalOpen && <Modal onRequestClose={toggleModal} />}
       <div id="loader-wrapper">
<div id="loader"></div>
<div className="loader-section section-left"></div>
<div className="loader-section section-right"></div>
</div>
      <a id="top"/>
      <img src={TC} className="logo"/>
      <button onClick={LogOff}></button>
     <div className="NEwheader-bottom">     
     <nav className="navbars">
     <img src={bg} height='300%' width='150%' className='bg'></img>
     
     </nav>
     <h1 className="tcwalleth1">Admin Console</h1>
    
     </div><br></br><br></br>
     <div class="DropdownMenu">
     <img src={log} className="logo-logout" onClick={LogOff} title="Logout"/>
      
  <ul className='ul'> 
    <li className='li' ><a href="#" style={{color:"#fff"}}>Activity </a>
      <ul className='ul'>
        <li className='li'><Link to='/Report'>Report</Link></li>
        <li className='li'><Link to="/Unfollow">Unfollow</Link></li>
        <li className='li'><Link to='/Winner'>Winner</Link></li>
        
      </ul>
    </li>
   
  </ul>
  
  </div>
     <div className="container-body">
    <div className="container">
    <div className="tc-card-container">
    <div className="tc-card" style={{width: '18rem'}}>
    <div className="card-body">
    {/* {data.filter(e=>e.Transaction_Id !=null).length === 0 ? <h5 className="tc-card-title">{data.length}</h5>:<h5 className="tc-card-title">0</h5> */}
    <h5 className="tc-card-title">{pending.Pending_Count == null ? 0 : pending.Pending_Count}</h5>
    
    <p className="tc-card-text">Pending Transaction</p>
    </div>
    </div>
    <div className="tc-card" style={{width: '18rem'}}>
    <div className="card-body">
    <h5 className="tc-card-title">{pending.Completed_Count == null ? 0 : pending.Completed_Count}</h5>
    <p className="tc-card-text">Completed Transaction</p>
    </div>
    </div>
    </div>
    </div>
    <div className="input-group tc-search-container">
  <input type="search" className="form-control rounded" placeholder="Search by Name or UPI Id.." aria-label="Search" aria-describedby="search-addon" onChange={e=>{setSearch(e.target.value)}}/>
  <button type="button" className="tc-btn-primary">search</button>
  </div>
    <div className="container wallet-tableContainer">
    <div className="table-responsive">
    {!Load ?
  <img className="loading" src={Loading} />
  :
<Table striped borderless responsive hover className="tc-table">
  <tr>
  <th className={comp == true ? 'btn-pen active' : 'btn-pen2'} onClick={pendisp}>Pending</th>
  <th className={comp == false ? 'btn-com active' : 'btn-com2'} onClick={comdisp}>Completed</th>
  </tr>
  <tr>
    <th>Transaction Id</th>
    <th>Name</th>
    <th>Req.Amt</th>
    <th>Transaction Type</th>
    <th>Bank Acc.No</th>
    <th>IFSC Code</th>
    <th>UPI Id</th>
    <th>Created Date</th>
    {actiondisp == false ? <th>Action</th> : null}
    </tr>
  {comp == true ? data.filter(item =>{
if(search == ""){
return item
}
else if(item.Bank_HolderName.toLowerCase().includes(search.toLowerCase()) || item.UPI_Id.toLowerCase().includes(search.toLowerCase())){
return item
}
}).length == 0 ? <td className="Nodata" colSpan="9">No Record Found</td>  :  data.filter(item =>{
    if(search == ""){
    return item
    }
    else if(item.Bank_HolderName.toLowerCase().includes(search.toLowerCase()) || item.UPI_Id.toLowerCase().includes(search.toLowerCase())){
    return item
    }
    }).
map(item=>
  <tr>
    {item.Transaction_Id == null ? 
    <>
    <td>{item.Transaction_Id?item.Transaction_Id:'Null'}</td>
    <td>{item.Bank_HolderName?item.Bank_HolderName:'Null'}</td>
    <td>{item.Req_Amount}</td>
    <td>{item.Mode == 'U' ? 'UPI' : 'Bank'}</td>
    <td>{item.Bank_AcNo?item.Bank_AcNo:'Null'}</td>
    <td>{item.Bank_IFSC_Code?item.Bank_IFSC_Code:'Null'}</td>
    <td>{item.UPI_Id?item.UPI_Id:'Null'}</td>
    <td className='winnerdata'>{moment(item.Transation_Time).format('DD/MM/yyyy')}</td>
    <td><button class='updatebutton' onClick={(e) => {toggleModal(item.Bank_HolderName,item.UPI_Id,item.Bank_AcNo, item.Id, e)}}><img src={edit} className="edit-logo" title="Edit"/></button></td>
    </>
    : null}
  </tr>
)
  :
  data.filter(item =>{
    if(search == ""){
    return item
    }
    else if(item.Bank_HolderName.toLowerCase().includes(search.toLowerCase()) || item.UPI_Id.toLowerCase().includes(search.toLowerCase())){
    return item
    }
    }).length == 0 ?<td className="Nodata" colSpan="9">No Record Found</td> : data.filter(item =>{
        if(search == ""){
        return item
        }
        else if(item.Bank_HolderName.toLowerCase().includes(search.toLowerCase()) || item.UPI_Id.toLowerCase().includes(search.toLowerCase())){
        return item
        }
        }).
    map(item=>(
  <tr>
    {item.Transaction_Id != null ? 
    <>
    <td>{item.Transaction_Id?item.Transaction_Id:'Null'}</td>
    <td>{item.Bank_HolderName?item.Bank_HolderName:'Null'}</td>
    <td>{item.Req_Amount}</td>
    <td>{item.Mode == 'U' ? 'UPI' : 'Bank'}</td>
    <td>{item.Bank_AcNo?item.Bank_AcNo:'Null'}</td>
    <td>{item.Bank_IFSC_Code?item.Bank_IFSC_Code:'Null'}</td>
    <td>{item.UPI_Id?item.UPI_Id:'Null'}</td>
    <td className='winnerdata'>{moment(item.Transation_Time).format('DD/MM/yyyy')}</td>
    </>
    : null}
    </tr>
  ))
}
</Table>
}
{isModalOpen?<Modal pass1={Bname} pass2={Upiid} pass3={Bankname} pass4={id}/>:null}

<div className="pagination_center">
<ReactPaginate previousLabel={"Previous"} nextLabel={"Next"} pageCount={pagelimit} onPageChange={changePage} containerClassName={"paginationBttns"} previousLinkClassName={"previousBttn"} nextLinkClassName={"nextBttn" } disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"}/>
</div>

</div>
</div>
</div> 
<Footer/>
</div>
</body>
}
</>
)
}
else{
return(
<div className="text-center">
  <p className="tc-error">404 Error Page Not found!</p> </div>
)
}
}
export default Tcwalletpage;

function Modal(props) {
  let formData = new FormData();
  const[text, setText] = useState('')
  const[show, setShow] = useState('false')
  function Close(e){
    e.preventDefault();
    window.location.reload();
  }
  let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
function modelform(e) {
  formData.append('Id', parseInt(`${props.pass4}`))
  formData.append('Transaction_Id', text)
  e.preventDefault()
  if(text == "")
  {
    alert('Enter Transaction Id')
  }
  else if(text.length < 5)
  {
    alert('Enter Valid Transaction Id')
  }
  else
  {
     
    axios({
      method: "post",
      url: `${BASE_URL}Admin/UpdateTransactionHistoryById`,
      data:formData,
       headers: {
       Authorization: "Bearer " + accessToken},
    })
    .then((resp)=>{
    
      window.location.reload(false);
    })
  }
  }
  function store(e) {
    e.preventDefault()
    setText(e.target.value)
  }
return(
  <div className="modal__backdrop">
  <div className="modal__container">
  <i className="fa fa-close" id='closeicon' onClick={(e) => Close(e)}></i>
  <p>Name : {props.pass1}</p>
  <p>{props.pass3 == null ? <p>UPI Id : <>{props.pass2}</></p> : <p>Bank Acc.No : <>{props.pass3}</></p>}</p>
  <form>
  <input id="trans" type="text" placeholder='Transaction Id' onChange={(e) => store(e)} className='trans' maxLength={25} autoComplete="off"/>
  </form>
  <img className="tick-img" src={tick} onClick={(e) => modelform(e)}/>
  </div>
  </div>
);
}