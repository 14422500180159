import React, { useState, useEffect } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import TC from '../Components/Assets/Images/TC3.png'
import {Link,useHistory,useNavigate } from 'react-router-dom';
import '../Components/Assets/Landingcss/Text.css';
import { BASE_URL } from '../Components/BaseURL';
function ForgetPasswordApp() {
    const [Error, setError] = useState("");
    const [Name, setName] = React.useState("");
    const [PhoneNumber, setPhoneNumber] = React.useState("");
    let history = useHistory();

    const submit=(event)=> {
        event.preventDefault();
         var name=document.getElementById("Name").value;
         var phone=document.getElementById("Phone").value;
        if(name==""){
          setError("! Name must be filed")
          return false;
      }
       if(phone==""){
        setError("! Phone Number must be filed")
        return false;
    }
      if(phone.length< 10) {
        setError("! Phone Number must be 10 characters")
       return false;
    }
      var data = {
        Name: Name,
        PhoneNumber: PhoneNumber,
      };
         
          fetch(`${BASE_URL}Profile/ForgotPassword`, {
            method: 'POST',
            headers:{
             'Content-type':'application/json'          
            },
            body:JSON.stringify(data)            
          }).then((result)=>{
              result.json().then((resp)=>{
            
                  if(resp.Errormessage=="Your Phone Number is not registered."){
                  //  this.setState({error:"! "+resp.Errormessage });  
                    setError("Your Phone Number is not registered.");
                  }
                  else{
                    setError("OTP send to your Phone number");
            // alert("OTP send to your Phone number")
            var page= {
                pathname: '/VerifyOTPApp',
                state: {
                data:{
                  OTP:JSON.stringify(resp.OTP),
                  Ph:JSON.stringify(resp.PhoneNumber),
                }
                 }
                }
                history.push(page);  
              }
              })
          });
          }
    return(
        <div className="forget">
        <div className="forgetform">
        <form className="forget-form" method="post" onSubmit={submit}  autoComplete="off">
        <img className="Logo" src={TC}></img>
        <h3 className='title'>Forgot Secret Code</h3>
        <p className='forgoterror'>{Error}</p>
        <input id="Name" autoComplete='off' onKeyPress={(event) => { if (!/[A-Z]/.test(event.key)&&!/[a-z]/.test(event.key)) { event.preventDefault(); } }} name="Name" onChange={e => setName(e.target.value)} value ={Name}type="text" placeholder="Enter your Name" title="Name"/>
        <input type="text" autoComplete='off' placeholder="Phone Number"   id="Phone" name="PhoneNumber"   onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} onChange={e => setPhoneNumber(e.target.value)} value={PhoneNumber} type="text"  title="Phone number" maxLength="10"/>
        <button type="submit">Send OTP</button>
        </form>
        </div>  
        </div>
    )
}
export default ForgetPasswordApp;