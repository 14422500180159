 
import '../Components/Assets/css/bootstrap.min.css'
import AsyncStorage from '@react-native-async-storage/async-storage';
import React,{useEffect,useState,useLayoutEffect} from 'react';
import { BASE_URL } from '../Components/BaseURL';
function ProfileTesting() {
  const [data,setData]=useState([]);
  const [data2,setData2]=useState([]);
  useLayoutEffect(()=>{
    getData();
    getData2();
  },[]);
 const getData=()=> {
   
     let userData =  AsyncStorage.getItem('token');
userData.then((a) => {
    const accessToken=JSON.parse(a);
    fetch(`${BASE_URL}Profile/GetProfileDetail`, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                Authorization: "Bearer " + accessToken,
                 "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
            },
            //body:JSON.stringify(data)
        }).then(response => response.json())
        .then(data => {
           setData(data);
          
        });
    });
}
 
 const getData2=()=> {
      
    fetch(`${BASE_URL}Profile/GetProfileDetail`, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiOTY1NTYxOTI4NyIsImp0aSI6IjliZTZjMDM4LWFmOTMtNDE4NS1iZDE0LTc3NTU5MGYxYTAyMyIsImV4cCI6MTgwMjA3MjY5NiwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzNTkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjY1NzgifQ.M7YnSRcdrJa8DriCAvulJ0VaZmQiJc0HSazfMgg1xis" ,
                "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
            },
            //body:JSON.stringify(data)
        }).then(response => response.json())
        .then(data => {
           setData2(data);
          
        });
}
    return(
        <div>
            <p style={{textAlign:'center'}}>With header</p>
        <div className="text-center new-css">
        {data.map(item=>
        <div>
        <input placeholder="Name" defaultValue={item.profile_name}/><br/><br/>
       
        </div>
         )}
         {data.map(item=>
        <div>
        <input placeholder="Name" defaultValue={item.profile_email}/><br/><br/>
       
        </div>
         )}  {data.map(item=>
            <div>
            <input placeholder="Name" defaultValue={item.profile_mobileNumber}/><br/><br/>
           
            </div>
             )}
        </div>

<p style={{textAlign:'center'}}>Without header</p>
        <div className="text-center new-css">
        {data2.map(item=>
        <div>
        <input placeholder="Name" defaultValue={item.profile_name}/><br/><br/>
       
        </div>
         )}
         {data2.map(item=>
        <div>
            <input placeholder="Name" defaultValue={item.profile_email}/><br/><br/>
       
        </div>
         )}  {data2.map(item=>
            <div>
            <input placeholder="Name" defaultValue={item.profile_mobileNumber}/><br/><br/>
           
            </div>
             )}
        </div>
        </div>
    )
}
export default ProfileTesting