import React, { useState, useEffect } from 'react'
import TC from '../Components/Assets/Images/TC3.png'
import bg from '../Components/Assets/Images/M4.png'
import {Link} from 'react-router-dom';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Privacy() {
    return(
        <div >
  <Navbar/>
<div className="termbody">
<h3 className="thc">Privacy Policy</h3>
<p className="termtext">KaiDP Project Advisors & KDP Project Management Private Limited (ThatsCool.in) recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on https://www.ThatsCool.in and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy. ThatsCool.in is a property of KaiDP Project Advisors & KDP Project Management Private Limited, an Indian Company registered under the Companies Act, 2013 having its registered office at 80, Neelanjana, 6th Main, 2nd Cross, J P Nagar 3rd Phase, Bengaluru - 560078.</p>
<h4 className="termhead-3">Information we collect</h4>
<p className="termtext">Contact information. We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and ip address. Information you post. We collect information you post in a public space on our website or on a third-party social media site belonging to ThatsCool.in Other information. If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.</p>
<h4 className="termhead-3">We collect information in different ways.</h4>
<p className="termtext">We collect information directly from you. We collect information directly from you when you register on our website. We also collect information if you post a comment on our websites or ask us a question through phone or email. We collect information from you passively. We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website. We get information about you from third parties. For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.</p>
<h4 className="termhead-3">Use of your personal information</h4>
<p className="termtext">We use information to contact you: We might use the information you provide to contact you for confirmation for promotional purposes. We use information to respond to your requests or questions. We might use your information to confirm your registration on our website. We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences. We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties. We use information for security purposes. We may use information to protect our company, our customers, or our websites. We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. We use the information to send you transactional communications. We might contact you via emails, phone or SMS(text messages) to deliver certain services or information about your account. We use information as otherwise permitted by law.</p>
<h4 className="termhead-3">Sharing of information with third-parties</h4>
<p className="termtext">We will share information with third parties who perform services on our behalf. We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India. We will share information with our business partners. This includes a third party who provide or sponsor an event. Our partners use the information we give them as described in their privacy policies. We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud. We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction. We may share your information for reasons not described in this policy. We will tell you before we do this.</p>
<h4 className="termhead-3">Email Opt-Out</h4>
<p className="termtext">You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please email unsubscribe@ThatsCool.in. It may take about thirty days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your content.</p>
<h4 className="termhead-3">Third party sites</h4>
<p className="termtext">If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third party sites.</p>
<h4 className="termhead-3">Grievance Officer</h4>
<p className="termtext">If you have any questions about this Policy or other privacy concerns, you can also email us at contact@ThatsCool.in</p>
<h4 className="termhead-3">Updates to this policy</h4>
<p className="termtext">This Privacy Policy was last updated on 01.01.2022. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</p>
<h4 className="termhead-3">Jurisdiction</h4>
<p className="termtext">If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>
<h3 className="termhead-3">Cancellation and Refund Policy</h3>
<p className="termtext">Due to service providers in nature “NO REFUND”, “NO CANCELLATION” will be entertained once the Payment has been made.</p>
</div>

 {/* Footer Part */}
 <Footer/>
        </div>
    );
}
export default Privacy