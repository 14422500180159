import React, { useState, useEffect } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Text.css'
import TC from '../Components/Assets/Images/TC3.png'
import pro from '../Components/Assets/Images/defaultprofilepic.png'
import cal from '../Components/Assets/Images/calender.png'
import {Link,useHistory,useNavigate } from 'react-router-dom';
import TextTransition, { presets } from "react-text-transition";
import bg from '../Components/Assets/Images/M4.png'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Faq() {

    const history = useHistory();
    // const Logo=()=> {
    //     history.push('/LandingPage');
    // }
    return(
        <div>
       <Navbar/>
 <div>
 
     <h4 className="termhead-faq" id='FAQ'>Frequently Asked Questions</h4>
 </div>
 {/* Footer Part */}
 <Footer/>
  
 </div>
    )
}
export default Faq;