import React from 'react';
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Text.css'
import TC from '../Components/Assets/Images/TC3.png'
import pro from '../Components/Assets/Images/defaultprofilepic.png'
import cal from '../Components/Assets/Images/calender.png'
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Axios from 'axios';
import $ from 'jquery';
import bg from '../Components/Assets/Images/M4.png'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Support() {
    return (
        <div>
            <body>
        <div>
        <Navbar/>
<div className="text-winner text-top">
<h1>Hi, How can we help you?</h1>
<h4 className="support-text">Contact Us</h4>
</div>
<div className="text-center" >
<p>ios@thatscool.in</p>
</div>
</div>
</body>
</div>
    );
}
export default Support;