import React, { useState, useEffect,useRef } from 'react'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Text.css'
import TC from '../Components/Assets/Images/TC3.png'
import log from '../Components/Assets/Images/logout.png'
import DatePicker from "react-datepicker";
import moment from 'moment'
import '../Components/Assets/Landingcss/Wallet.css';
import '../Components/Assets/Landingcss/Winner.css';
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import { useHistory, useNavigate } from "react-router-dom";
import bg from '../Components/Assets/Images/M4.png'
import Loading from '../Components/Assets/Images/Spinner.gif'
import Loads from '../Components/Assets/Images/load.gif'
import 'font-awesome/css/font-awesome.min.css';
import { Link } from "react-router-dom";
import Footer from '../Components/Footer'
import { BASE_URL } from '../Components/BaseURL'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
function Winner() {
 const tableRef = useRef(null);  
  const[pending, setpending] = useState([])
  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  var firstDay = new Date(y, m, 1); 
  const [startDate, setStartDate] = useState(firstDay);
    const [pagenumber,setpagenumber]=useState(0)
    const [data,setData]=useState([])
    const [allposts,setallposts]=useState([])
    const [getstockfull,Setgetstockfull] = useState([])
    const [Load,setLoad] = useState(false) 
    const [comp, setComp] = useState(true);
    const pagepercount=5;
    const pagevisited=pagenumber*pagepercount;
    const pagelimit=Math.ceil(getstockfull.length/pagepercount)
    let history = useHistory();
    function changePage({selected}){
    setpagenumber(selected)
    }
  
   const filterDate =(date)=>{
      setStartDate(date)
    setLoad(true);
   let user = JSON.parse(localStorage.getItem('user'));
   const accessToken = user;
   axios.get(`${BASE_URL}Winner/winnerRecord?filterDate=${moment(date).format('ddd MMM DD YYYY')}`,{ headers: {Authorization: "Bearer " + accessToken} })
   .then(response => {
     if(response.data[0]!=null){
           setpending(response.data[0])
         }
   setData(response.data.slice(pagevisited,pagevisited+pagepercount))
   Setgetstockfull(response.data)
   setallposts(response.data);
   setLoad(false);
   })
   }
   function GetFormattedDate(date) {
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day  = ("0" + (date.getDate())).slice(-2);
    var year = date.getFullYear();
    var hour =  ("0" + (date.getHours())).slice(-2);
    var min =  ("0" + (date.getMinutes())).slice(-2);
    var seg = ("0" + (date.getSeconds())).slice(-2);
    return year + "-" + month + "-" + day + " - " + hour + "-" +  min ;
}
  const LogOff=()=> {
    var x = window.confirm("Are you sure want to logout?");
    if (x){
    localStorage.clear();
        var page= {
            pathname: '/Tcwalletadmin'
             }
           history.push(page);
          }
          else{
             return false;
         }   
          }
       
          let data2=localStorage.getItem('user')
 
      if(data2!=null){
    return(
      <>
     
      <body className="">
        <div className="bg-image bgp">
         
       <div id="loader-wrapper">
<div id="loader"></div>
<div className="loader-section section-left"></div>
<div className="loader-section section-right"></div>
</div>
      <a id="top"/>
      <img src={TC} className="logo"/>
      <button onClick={LogOff}></button>
     <div className="NEwheader-bottom">     
     <nav className="navbars">
     <img src={bg} height='300%' width='150%' className='bg'></img>
     
     </nav>
     <h1 className="tcwalleth1">Admin Console</h1>
    
     </div><br></br><br></br>
     <div class="DropdownMenu">
     <img src={log} className="logo-logout" onClick={LogOff} title="Logout"/>
      
  <ul className='ul'> 
    <li className='li' ><a href="#" style={{color:"#fff"}}>Activity </a>
      <ul className='ul'>
        <li className='li'><Link to='/Report'>Report</Link></li>
        <li className='li'><Link to="/Unfollow">Unfollow</Link></li>
        <li className='li'><Link to='/Winner'>Winner</Link></li>
        
      </ul>
    </li>
   
  </ul>
  
  </div>
     <div className="container-body">
    
     <div className="input-group tc-search-container"  id='filtercontainer'>
    
              <form className='filterform'> 
             
              <ReactHTMLTableToExcel
       className="header_btn"
       id="filterButton"
        table="tblData"
        filename={startDate+' WinnerReport'}
        sheet="tablexls"
        buttonText= 'Export to Excel'
      />
          &nbsp; &nbsp; &nbsp;  &nbsp;   <label style={{width:'35%',textAlign:'center'}} >Filter By Date</label>  
  <DatePicker id='commonSearchTerm'
                onChange={filterDate} placeholderText={'Filter By Date'}
                name="startDate"
                selected={startDate}
                dateFormat="dd/MM/yyyy" 
                 maxDate={new Date()}
              />
 
</form>
              

              </div><br/>
    <div className="container wallet-tableContainer">
    <div className="table-responsive">
    {Load==true ?
  <img className="loading" src={Loading} />
  :
<Table id="tblData" ref={tableRef} striped borderless responsive hover className="tc-table">
   
  <tr>
    <th>Upload Id</th>
    <th>Upload Title</th>
    <th>Name</th>
    <th>Rank No.</th>
    <th>Winner Amount</th>
   <th>Winner Date</th>
    </tr>
  {data.map(item=>
  <tr> 
    <>
    <td className='winnerdata'>{item.upload_id}</td>
    <td className='winnerdata'>{item.upload_title}</td>
    <td className='winnerdata'>{item.profile_name}</td>
    <td className='winnerdata'>{item.Rank_No}</td>
    <td className='winnerdata'>{item.Win_Amt}</td> 
    <td className='winnerdata'>{moment(item.Created_Date).format('DD/MM/yyyy')}</td>
   </> 
  </tr> 
  ) 
}
</Table>
}
 

<div className="pagination_center">
<ReactPaginate previousLabel={"Previous"} nextLabel={"Next"} pageCount={pagelimit} onPageChange={changePage} containerClassName={"paginationBttns"} previousLinkClassName={"previousBttn"} nextLinkClassName={"nextBttn" } disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"}/>
</div>

</div>
</div>
</div> 
<Footer/>
</div>
</body>
 
</>
)
}
else{
return(
<div className="text-center">
  <p className="tc-error">404 Error Page Not found!</p> </div>
)
}
}
export default Winner;

 