import React, {useState} from 'react';
import TC from '../Components/Assets/Images/TC3.png'
import axios from 'axios'
import { BASE_URL } from '../Components/BaseURL';
import Loading from '../Components/Assets/Images/load.gif'
function Tcwalletadmin() {
  const [data, setData] = useState({
    username : "",
    password : "",
  })
  const [load, setLoad] = useState(false);
  function store(e) {
    const newdata={...data}
    newdata[e.target.id]=e.target.value
    setData(newdata)
  }
  function validateForm() {
    const passpattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    let em = document.getElementById('username').value
    let pwd = document.getElementById('password').value
    let body = {
      username:data.username, 
      OSX_Platform:'A',
      Version:'1.0.71',
      Device_Id:"NoTokenForAdmin"
    };
    
    if(em.length == "" || em == null)
    {
      alert('Enter Username')
    }
    else if(em.length<10){
      alert('Enter Valid Mobile Number')
    }
    else if(pwd.length == "" || pwd == null)
    {
      alert('Enter Password')
    }
    else if(!passpattern.test([pwd]))
    {
      alert('Enter valid Password')
    }
    else if(em!='9999999999' || pwd!='Admin@123'){
      alert("Enter Correct Username and Password")

    }
   
    else{
     
      setLoad(true)
      axios({
        method: "post",
        url: `${BASE_URL}Authenticate/login`,
        data: JSON.stringify(body),
        mode:'cors',
        headers: {
          "Content-Type": "application/json",
           Accept: "application/json",
          'Access-Control-Allow-Origin':'*',
          'Access-Control-Allow-Credentials':true,
        },
      })
      .then((resp)=>{
       
          if(resp.data.token != '')
          {
           
            //alert("Hai")
              localStorage.setItem('user', JSON.stringify(resp.data.token));
              setLoad(false)
            window.location.href="#/Tcwalletpage"
          }
          else
          {
            alert("Enter Correct Username and Password")

          }
      })
    }
  }

  
    return(
      
    <body>
      {load==true?
     <center>
        <img className="loadings" src={Loading}></img></center> :
    <div className="adm-body">
    <div>
    <img src={TC} className="logo"/>
    </div>
    <div className="admform adm-top">
    <form onSubmit={validateForm} autoComplete='off'>
    <h1 className="adm-h1">Login</h1>
    <div className="row">
    <input type="text" maxLength='10' id="username" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} name="username" placeholder="UserName" minLength={10} maxLength="10" autoComplete="off" onChange={(e)=>store(e)}/>
    </div>
    <div className="row">
    <input type="password" id="password" name="password" placeholder="Password" maxLength="16" autocomplete="off" onChange={(e)=>store(e)}/>
    </div>
    </form>
    <input className="adm-btn" type="submit" value="Login" onClick={validateForm}/>
    </div>
    </div>}
    </body>
    );
}
export default Tcwalletadmin