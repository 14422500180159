import 'font-awesome/css/font-awesome.min.css'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Report.css'

import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from 'react'

import Loading from '../Components/Assets/Images/Spinner.gif'
import Loads from '../Components/Assets/Images/load.gif'
import ReactPaginate from "react-paginate"
import TC from '../Components/Assets/Images/TC3.png'
import { Table } from 'react-bootstrap'
import axios from 'axios'
import bg from '../Components/Assets/Images/M4.png'
import log from '../Components/Assets/Images/logout.png'
import moment from 'moment'
import tick from '../Components/Assets/Images/tick.png'
import Footer from '../Components/Footer'
import { BASE_URL } from '../Components/BaseURL'
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function Report() { 
  const[pending, setpending] = useState([]) 
    const [Bname, setBname] = useState('')
    const [Upiid, setUpiid] = useState('')
    const [id, setid] = useState(0)
    const [Bankname, setBankname] = useState('')
    const [pagenumber,setpagenumber]=useState(0)
    const [allposts,setallposts]=useState([])
    const [getstockfull,Setgetstockfull] = useState([])
    const [Load,setLoad] = useState(false)
    const [isModalOpen, setModalIsOpen] = useState(false); 
    const [VerifiedReport,SetVerifiedReport] = useState('')
    const [UnVerifiedReport,SetUnVerifiedReport] = useState('');
    const [Verifiedflag, setVerifiedflag] = useState(false); 
    const[actiondisp, setActiondisp] = useState(false)
    const [Complete,SetComplete] = useState([])
    const [comp, setComp] = useState(true);
    const pagepercount=3;
    const pagevisited=pagenumber*pagepercount;
    const pagelimit=Math.ceil(getstockfull.length/pagepercount)
    let history = useHistory();
    function changePage({selected}){
    setpagenumber(selected)
    }
    
    useEffect(()=>{
      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;
      axios.get(`${BASE_URL}Report/VerifiedReportsCount`,{ headers: {Authorization: "Bearer " + accessToken} })
      .then(response => {
    
        SetComplete(response.data.slice(pagevisited,pagevisited+pagepercount))
        if(response.data.length==0){
          SetVerifiedReport(0)
        }
        else{
       SetVerifiedReport(response.data[0].VerifiedReportsCount)
        }
      })
      axios.get(`${BASE_URL}Report/UnVerifiedReportsCount`,{ headers: {Authorization: "Bearer " + accessToken} })
      .then(response => {
       SetUnVerifiedReport(response.data[0].UnVerifiedReportsCount)
      })

    },[Verifiedflag])
 
    function toggleModal(p, a, b, d) {
      setModalIsOpen(!isModalOpen);
      setBname(p)
      setUpiid(a)
      setBankname(b)
      setid(d)
    }
   const Verify=(item)=>{
     
    var x = window.confirm("Are you sure want to Report?");
    if (x){
     
    setVerifiedflag(true);
    axios({
      method: "post",
      url: `${BASE_URL}Report/UpdateReportFlag?S_No=${item.S_No}&upload_id=${item.upload_id}`,
       
    })
    .then((resp)=>{
      alert("Reported Successfully")
      setVerifiedflag(false);
    })
   }
   else{
    return false;
   }
  }
   function comdisp() {
    
    setComp(false)
    setActiondisp(true)
    
  }
  async function pendisp() {
    setComp(true)
    setActiondisp(false)
   
  }  
    useEffect(() => {         
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    axios.get(`${BASE_URL}Report/AdminReport`,{ headers: {Authorization: "Bearer " + accessToken} })
    .then(response => {
      
    Setgetstockfull(response.data)
    setallposts(response.data.slice(pagevisited,pagevisited+pagepercount))
    setLoad(true);
    })
    }, [pagenumber,Verifiedflag]);
  
  const LogOff=()=> {
    var x = window.confirm("Are you sure want to logout?");
    if (x){
    localStorage.clear();
        var page= {
            pathname: '/Tcwalletadmin'
             }
           history.push(page);
          }
          else{
             return false;
         }   
          }
       
          let data2=localStorage.getItem('user')
      if(data2!=null){
    return(
      <>
      {!Load? <img className="loadings" src={Loads} /> 
        :
      <body className="">
        <div className="bg-image bgp">
        {isModalOpen && <Modal onRequestClose={toggleModal} />}
       <div id="loader-wrapper">
<div id="loader"></div>
<div className="loader-section section-left"></div>
<div className="loader-section section-right"></div>
</div>
      <a id="top"/>
      <img src={TC} className="logo"/>
      <button onClick={LogOff}></button>
     <div className="NEwheader-bottom">     
     <nav className="navbars">
     <img src={bg} height='300%' width='150%' className='bg'></img>
     
     </nav>
     <h1 className="tcwalleth1">Admin Console</h1>
    
     </div><br></br><br></br>
     
     <div class="DropdownMenu">
     <img src={log} className="logo-logout" onClick={LogOff} title="Logout"/>
      
  <ul className='ul'> 
    <li className='li' ><a href="#" style={{color:"#fff"}}>Activity</a>
      <ul className='ul'>
        <li className='li'><Link to='/Report'>Report</Link></li>
        <li className='li'><Link to="/Unfollow">Unfollow</Link></li>
        <li className='li'><Link to='/Winner'>Winner</Link></li>
        
      </ul>
    </li>
   
  </ul>
  

  </div>
  {/* <div id="icon">
  <img src={log} className="logo-logout" onClick={LogOff} title="Logout"/>
  
  <Link to='/Report' className="reportmenu">Report</Link>
</div> */}

      
  
    
     <div className="container-body">
     &nbsp;&nbsp;<button onClick={()=>history.push('/tcwalletpage')} class="btns btns-success btns-round-1"><span class="fa fa-reply" aria-hidden="true"></span> Back</button>
      
    <div className="container">
    <div className="tc-card-container">
    <div className="tc-card" style={{width: '18rem'}}>
    <div className="card-body">
    {/* {data.filter(e=>e.Transaction_Id !=null).length === 0 ? <h5 className="tc-card-title">{data.length}</h5>:<h5 className="tc-card-title">0</h5> */}
    <h5 className="tc-card-title">{UnVerifiedReport}</h5>
    
    <p className="tc-card-text">Pending Report</p>
    </div>
    </div>
    <div className="tc-card" style={{width: '18rem'}}>
    <div className="card-body">
    <h5 className="tc-card-title">{VerifiedReport} </h5>
    <p className="tc-card-text">Completed Report</p>
    </div>
    </div>
    </div>
    </div>
  
    <div className="container wallet-tableContainer">
    <div className="table-responsive">
    {!Load ?
  <img className="loading" src={Loading} />
  :
<Table striped borderless responsive hover className="tc-table">
<tr>
  <th className={comp == true ? 'btn-pen active' : 'btn-pen2'} onClick={()=>pendisp()}>Pending</th>
  <th className={comp == false ? 'btn-com active' : 'btn-com2'} onClick={()=>comdisp()}>Completed</th>
  </tr>
  <tr>
    <th>Posted By</th>
    <th>Upload title</th>
    <th>Post Detail</th>
    <th>Reported By</th>
    <th>Reported Cause</th>
    <th>Reported Time</th>
    <th>Action</th>     
    </tr>
    {comp == true ?
    allposts.map(item=>
    <tr>
    <td>{item.PostOwner}</td>
    <td>{item.upload_title}</td>
    <td ><a className='reportfilename'  style={{backgroundColor:'transparent'}}href={item.file_name} target="_blank">{item.file_name.split("/").pop()}</a></td>
    <td >{item.ReportedByName}</td>
    <td >{item.Report_Name}</td>
    <td><span    style={{backgroundColor:'transparent'}} className='reporttime'>{month[item.ReportedTime.slice(6, 7) - 1]}{" "+item.ReportedTime.slice(8, 10)},{" "+item.ReportedTime.slice(0, 4)}{"  "+moment(item.ReportedTime).format("LT")}</span></td>
    <td><button class="btns btns-primary btns-round-1" style={{backgroundColor:'#3498db',color:'#fff'}} onClick={()=>Verify(item)}>Report</button></td>    
      </tr>
      ):
        Complete.map(item=>
          <tr>
          <td>{item.PostOwner}</td>
          <td>{item.upload_title}</td>
          <td ><a className='reportfilename'  style={{backgroundColor:'transparent'}}href={item.file_name} target="_blank">{item.file_name.split("/").pop()}</a></td>
          
          <td >{item.ReportedByName}</td>
          <td >{item.Report_Name}</td>
          <td><span    style={{backgroundColor:'transparent'}} className='reporttime'>{month[item.ReportedTime.slice(6, 7) - 1]}{" "+item.ReportedTime.slice(8, 10)},{" "+item.ReportedTime.slice(0, 4)}{"  "+moment(item.ReportedTime).format("LT")}</span></td>
          <td><button class="btns btns-success btns-round-1" style={{cursor:'auto',backgroundColor:'#270'}} >Complete</button></td>    
            </tr>
      
      )
    }
</Table>
}
{isModalOpen?<Modal pass1={Bname} pass2={Upiid} pass3={Bankname} pass4={id}/>:null}

<div className="pagination_center">
<ReactPaginate previousLabel={"Previous"} nextLabel={"Next"} pageCount={pagelimit} onPageChange={changePage} containerClassName={"paginationBttns"} previousLinkClassName={"previousBttn"} nextLinkClassName={"nextBttn" } disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"}/>
</div>

</div>
</div>
</div>

 {/* Footer Part */}
 <Footer/>

</div>
</body>
}
</>
)
}
else{
return(
<div className="text-center">
  <p className="tc-error">404 Error Page Not found!</p> </div>
)
}
}
export default Report;

function Modal(props) {
  let formData = new FormData();
  const[text, setText] = useState('')
  const[show, setShow] = useState('false')
  function Close(e){
    e.preventDefault();
    window.location.reload();
  }
  let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
function modelform(e) {
  formData.append('Id', parseInt(`${props.pass4}`))
  formData.append('Transaction_Id', text)
  e.preventDefault()
  if(text == "")
  {
    alert('Enter Transaction Id')
  }
  else if(text.length < 5)
  {
    alert('Enter Valid Transaction Id')
  }
  else
  {
     
    axios({
      method: "post",
      url: `${BASE_URL}Report/UpdateTransactionHistoryById`,
      data:formData,
       headers: {
       Authorization: "Bearer " + accessToken},
    })
    .then((resp)=>{
    
      window.location.reload(false);
    })
  }
  }
  function store(e) {
    e.preventDefault()
    setText(e.target.value)
  }
return(
  <div className="modal__backdrop">
  <div className="modal__container">
  <i className="fa fa-close" id='closeicon' onClick={(e) => Close(e)}></i>
  <p>Name : {props.pass1}</p>
  <p>{props.pass3 == null ? <p>UPI Id : <>{props.pass2}</></p> : <p>Bank Acc.No : <>{props.pass3}</></p>}</p>
  <form>
  <input id="trans" type="text" placeholder='Transaction Id' onChange={(e) => store(e)} className='trans' maxLength={25} autoComplete="off"/>
  </form>
  <img className="tick-img" src={tick} onClick={(e) => modelform(e)}/>
  </div>
  </div>
);
}