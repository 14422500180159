import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import '../Components/Assets/Landingcss/HowItWorks.scss'
import $ from 'jquery'
function HowItWorks(props) {
    var btn = $('#button');

$(window).scroll(function() {
  if ($(window).scrollTop() > 300) {
    btn.addClass('show');
  } else {
    btn.removeClass('show');
  }
});

btn.on('click', function(e) {
  e.preventDefault();
  $('html, body').animate({scrollTop:0}, '300');
});


    return (
        <div>
            <Navbar/><br/>
            <main id="card">
  <header>
    <h1 className='HeaderHow'>How it Works</h1>
  </header>
  <section>
  <iframe width="320" height="305" src="https://www.youtube.com/embed/4WxSjJYhrlo">
</iframe>
    <p>Login/Register </p><a className='clicktosee' target={'blank'} href='https://youtu.be/4WxSjJYhrlo'>Click to see more</a>
  </section>
  <section>
  <iframe width="320" height="305" src="https://www.youtube.com/embed/URBPdvgCsvw">
</iframe>
    <p>ShowOff  </p><a className='clicktosee' target={'blank'} href='https://www.youtube.com/watch?v=URBPdvgCsvw'>Click to see more</a>
  </section>
  <section>
  <iframe width="320" height="305" src="https://www.youtube.com/embed/uEOF1DpnPLk">
</iframe>
    <p>Invite</p> <a className='clicktosee' target={'blank'} href='https://www.youtube.com/watch?v=uEOF1DpnPLk'>Click to see more</a>
  </section>
  {/* <section>
  <iframe width="320" height="305" src="https://www.youtube.com/embed/tgbNymZ7vqY">
</iframe>
    <p>Share </p>
  </section> */}
  {/* <footer>
    <button>Go Shopping</button>
  </footer> */}
</main>
   
<a id="buttonarrow"></a>
           <Footer/>
        </div>
    );
}

export default HowItWorks;