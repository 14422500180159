import React, { useState, useEffect } from 'react'
import TC from '../Components/Assets/Images/TC3.png'
import bg from '../Components/Assets/Images/M4.png'
import {Link} from 'react-router-dom';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Aboutus() {
    return(
    <div>
   <Navbar/>
<div className="termbody">
<h3 className="thc">About Us</h3>
<p className="termtext">30/6, Karthik Tower 2nd Floor, Church 7th Street, TT Nagar, <br/>Karaikudi, Tamil Nadu 630001<br/><strong>Email:</strong> contact@thatscool.in</p>
<p className="termtext">That’s Cool! a platform created by KaiDP Project Advisors Pvt Ltd and KaiDP Technolgies LLP </p>
<p className="termtext">Be Cool, Show Off, Win!</p>
<p className="termtext">That’s Cool! a positive platform that allows you to be fun and bold while rewarding you with cash prizes, all for free!</p>
</div>

 {/* Footer Part */}
  <Footer/>
        </div>
    );
}
export default Aboutus