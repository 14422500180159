import 'font-awesome/css/font-awesome.min.css'
import '../Components/Assets/css/bootstrap.min.css'
import '../Components/Assets/Landingcss/Report.css'

import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from 'react'

import Loading from '../Components/Assets/Images/Spinner.gif'
import Loads from '../Components/Assets/Images/load.gif'
import ReactPaginate from "react-paginate"
import TC from '../Components/Assets/Images/TC3.png'
import { Table } from 'react-bootstrap'
import axios from 'axios'
import bg from '../Components/Assets/Images/M4.png'
import log from '../Components/Assets/Images/logout.png'
import moment from 'moment'
import tick from '../Components/Assets/Images/tick.png'
import Footer from '../Components/Footer'
function Unfollow(props) {
    let history = useHistory();
    const LogOff=()=> {
        var x = window.confirm("Are you sure want to logout?");
        if (x){
        localStorage.clear();
            var page= {
                pathname: '/Tcwalletadmin'
                 }
               history.push(page);
              }
              else{
                 return false;
             }   
              }
    return (
        <div>
             <div id="loader-wrapper">
<div id="loader"></div>
<div className="loader-section section-left"></div>
<div className="loader-section section-right"></div>
</div>
      <a id="top"/>
      <img src={TC} className="logo"/>
      <button onClick={LogOff}></button>
     <div className="NEwheader-bottom">     
     <nav className="navbars">
     <img src={bg} height='300%' width='150%' className='bg'></img>
     
     </nav>
     <h1 className="tcwalleth1">Admin Console</h1>
    
     </div><br></br><br></br>
     
     <div class="DropdownMenu">
     <img src={log} className="logo-logout" onClick={LogOff} title="Logout"/>
      
  <ul className='ul'> 
    <li className='li' ><a href="#" style={{color:"#fff"}}>Activity</a>
      <ul className='ul'>
        <li className='li'><Link to='/Report'>Report</Link></li>
        <li className='li'><Link to="/Unfollow">Unfollow</Link></li>
        <li className='li'><Link to='/Winner'>Winner</Link></li>
        
      </ul>
    </li>
   
  </ul>
  

  </div>
            <br/>
            <h1 style={{textAlign:'center'}}>Coming Soon</h1>
             <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/><br/> <br/> <br/><br/> <br/>
           
            <Footer/>
        </div>
    );
}

export default Unfollow;